import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const SnackbarContext = React.createContext();

// Custom hook to use the Snackbar context
export const useSnackbar = () => React.useContext(SnackbarContext);

// Snackbar Provider component that will wrap your app
export const SnackbarProvider = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [autoHideDuration, setAutoHideDuration] = React.useState(3000);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');

  // Function to show the Snackbar
  const showSnackbar = ({
    message,
    severity = 'success',
    autoHideDuration = 3000,
  }) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
    setAutoHideDuration(autoHideDuration);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={autoHideDuration}
        onClose={closeSnackbar}
      >
        <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
