import { CssBaseline } from '@mui/material';
import './App.css';
import Router from './router/router';
import { SnackbarProvider } from './providers/SnackBarProvider';
import { AuthProvider } from './providers/AuthProvider';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <CssBaseline />
        <SnackbarProvider>
          <Router />
        </SnackbarProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
