import {
  Box,
  AppBar,
  Typography,
  Toolbar,
  Drawer,
  List,
  ListItemText,
  ListItem,
  IconButton,
  ListItemButton,
} from '@mui/material';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { APP_ROUTE } from '../router/constant';
import Button from './Button';
import logo from '../assets/logo/GOO_WHITE_REC.png';
import { useAuth } from '../providers/AuthProvider';

const DRAWER_WIDTH = 240;
const APP_BAR_HEIGHT = 64; // Specify the height of the AppBar

const DRAWER_MENU_LIST = [
  { path: APP_ROUTE.DASHBOARD.PATH, title: APP_ROUTE.DASHBOARD.TITLE },
  {
    path: APP_ROUTE.SEARCH_KEYWORD_HISTORY.PATH,
    title: APP_ROUTE.SEARCH_KEYWORD_HISTORY.TITLE,
  },
];

const DrawerWithAppBar = () => {
  const location = useLocation();
  const { signout } = useAuth();
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          height: `${APP_BAR_HEIGHT}px`, // Set the AppBar height
          width: `calc(100% - ${DRAWER_WIDTH}px)`,
          ml: `${DRAWER_WIDTH}px`,
        }}
      >
        <Toolbar
          sx={{
            minHeight: `${APP_BAR_HEIGHT}px`,
            justifyContent: 'space-between',
          }}
        >
          {' '}
          {/* Ensure Toolbar matches the AppBar height */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h4" noWrap component="div">
            {DRAWER_MENU_LIST.find((menu) => location.pathname === menu.path)
              ?.title || ''}
          </Typography>
          <Button color="inherit" variant="outlined" onClick={signout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Drawer
        variant="permanent"
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: DRAWER_WIDTH,
            boxSizing: 'border-box',
          },
        }}
      >
        <Box
          component="img"
          sx={{
            minHeight: `${APP_BAR_HEIGHT}px`, // Space for the AppBar
            p: 3,
          }}
          alt="GoooSearch logo"
          src={logo}
        />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {DRAWER_MENU_LIST.map((menu, index) => (
              <ListItem
                key={menu.path}
                component={ListItemButton}
                to={menu.path}
                selected={location.pathname === menu.path}
              >
                <ListItemText primary={menu.title} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

const Layout = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      {/* Drawer */}
      <DrawerWithAppBar />

      {/* Page Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          position: 'relative',
          top: `${APP_BAR_HEIGHT}px`, // Align to top, below the AppBar
          height: `calc(100vh - ${APP_BAR_HEIGHT}px)`, // Fill the remaining vertical space
          padding: '20px', // Remove any padding
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
