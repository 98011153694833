import api from '../utils/api';

const SearchService = {
  searchKeywords: async (keywords) => {
    try {
      const response = await api.post(`/scrape/scrape-by-keywords`, {
        keywords,
      });
      return response.data;
    } catch (error) {
      console.error('Error searching keywords:', error);
      throw error;
    }
  },

  getSearchCollectionsByQuery: async (query = {}) => {
    try {
      const { startDate, endDate, page, limit } = query;
      const response = await api.get(`/search-collection`, {
        params: {
          startDate,
          endDate,
          page,
          limit,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error searching keywords:', error);
      throw error;
    }
  },

  getSearchCollectionById: async (id) => {
    try {
      const response = await api.get(`/search-collection/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error get collection:', error);
      throw error;
    }
  },

  getSearchResultsByQuery: async ({
    searchCollectionId,
    keyword,
    page,
    limit,
  }) => {
    try {
      const response = await api.get(`/search-result`, {
        params: { searchCollectionId, keyword, page, limit },
      });
      return response.data;
    } catch (error) {
      console.error('Error searching keywords:', error);
      throw error;
    }
  },

  getSearchResultById: async (id) => {
    try {
      const response = await api.get(`/search-result/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error searching keywords:', error);
      throw error;
    }
  },
};

export default SearchService;
