import React, { useState } from 'react';
import { Container, TextField, Box, Paper } from '@mui/material';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import logo from '../../assets/logo/GOO_WHITE_REC.png';
import { useAuth } from '../../providers/AuthProvider';
import { useSnackbar } from '../../providers/SnackBarProvider';

const SignUpPage = () => {
  const [formValues, setFormValues] = useState({
    username: '',
    password: '',
  });
  const { signup } = useAuth();
  const { showSnackbar } = useSnackbar();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password } = formValues;
    try {
      setFormValues({
        username: '',
        password: '',
      });
      await signup({ username, password });
      showSnackbar({
        message: 'Signed up successfully!',
        severity: 'success',
      });
    } catch (error) {
      if (error?.response?.data?.error === 'Username already been used') {
        showSnackbar({
          message: 'Username already been used. Please try again.',
          severity: 'error',
        });
      } else {
        showSnackbar({
          message: 'Sign up failed. Please try again.',
          severity: 'error',
        });
      }
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        mt: '8vh',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          sx={{
            width: '100%',
            maxWidth: { xs: 350, md: 250 },
            p: 3,
          }}
          alt="GoooSearch logo"
          src={logo}
        />

        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            mt: 3,
          }}
        >
          <Box sx={{ mb: 2 }}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              value={formValues.username}
              onChange={handleInputChange}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formValues.password}
              onChange={handleInputChange}
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            sx={{ mt: 2 }}
          >
            Sign Up
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button href="/signin" variant="body2">
              Already have an account? Sign in
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default SignUpPage;
