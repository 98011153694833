import React, { useState } from 'react';
import { Box, TextField, Button, Paper, Typography } from '@mui/material';
import SearchResultsBox from '../common/SearchResultsBox';

const SearchKeywordHistoryPage = () => {
  const [keyword, setKeyword] = useState('');
  const [findKeyword, setFindKeyword] = useState(undefined);

  const handleClickFind = () => {
    setFindKeyword(keyword);
  };

  const handleClickReset = () => {
    setFindKeyword(undefined);
  };

  return (
    <Box sx={{ p: 4, height: '100%' }}>
      <Paper
        sx={{
          p: 2,
          mb: 4,
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          {'Find keyword'}
          {findKeyword && `: ${findKeyword}`}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
          <TextField
            label="Search by ID or Status"
            variant="outlined"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleClickFind}>
            Find
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickReset}
          >
            Reset
          </Button>
        </Box>
      </Paper>

      {findKeyword && <SearchResultsBox keyword={findKeyword} />}
    </Box>
  );
};

export default SearchKeywordHistoryPage;
