import React from 'react';
import { Box, Typography, Paper, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import SearchService from '../../services/Search';
import datetimeDisplay from '../../utils/datetimeDisplay';
import SearchResultsBox from '../common/SearchResultsBox';
import { useSnackbar } from '../../providers/SnackBarProvider';

const SearchCollectionDetailPage = () => {
  const { id } = useParams();
  const [searchCollection, setSearchCollection] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const { showSnackbar } = useSnackbar();

  React.useEffect(() => {
    const fetchSearchCollectionData = async () => {
      setLoading(true);
      try {
        const result = await SearchService.getSearchCollectionById(id);
        const { searchCollection } = result;

        setSearchCollection({
          id: searchCollection.id,
          searchAt: searchCollection.search_at,
          status: searchCollection.status,
          userId: searchCollection.user_id,
          totalKeyword: searchCollection.total_keyword,
        });

        setLoading(false);
      } catch (error) {
        showSnackbar({
          message: 'Loading failed. Please try again later.',
          severity: 'error',
        });
      }
    };

    fetchSearchCollectionData();
  }, [showSnackbar, id]);

  return (
    <Box sx={{ padding: 4 }}>
      {loading || !searchCollection ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Paper
            elevation={3}
            sx={{
              padding: 3,
              mb: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography variant="h5" sx={{ mb: 2 }}>
              Search Collection Details
            </Typography>
            <Typography variant="body1">
              <strong>ID:</strong> {searchCollection.id}
            </Typography>
            <Typography variant="body1">
              <strong>Search At:</strong>{' '}
              {datetimeDisplay(searchCollection.searchAt)}
            </Typography>
            <Typography variant="body1">
              <strong>Total Keywords:</strong> {searchCollection.totalKeyword}
            </Typography>
            <Typography variant="body1">
              <strong>Status:</strong> {searchCollection.status}
            </Typography>
          </Paper>

          <SearchResultsBox searchCollectionId={id} />
        </>
      )}
    </Box>
  );
};

export default SearchCollectionDetailPage;
