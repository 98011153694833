import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  Navigate,
} from 'react-router-dom';
import * as React from 'react';
import Layout from '../components/Layout';
import { APP_ROUTE } from './constant';
import DashboardPage from '../pages/Dashboard/Dashboard';
import SignInPage from '../pages/Auth/Signin';
import { jwtDecode } from 'jwt-decode';
import SignUpPage from '../pages/Auth/Signup';
import SearchCollectionDetailPage from '../pages/SearchCollection/SearchCollectionDetail';
import SearchKeywordHistoryPage from '../pages/SearchKeywordHistory/SearchKeywordHistory';
import SearchResultDetailPage from '../pages/SearchResult/SearchResultDetail';
import { useAuth } from '../providers/AuthProvider';

const RequireAuth = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    // Check for token in localStorage
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        const userData = jwtDecode(token);
        const currentTime = Date.now() / 1000; // in seconds

        if (userData.exp < currentTime) {
          return <Navigate to="/signin" state={{ from: location }} replace />;
        } else {
          auth.setUserData(userData); // Set the user if the token is valid
        }
      } catch (error) {
        console.error('Invalid token:', error);
        return <Navigate to="/signin" state={{ from: location }} replace />;
      }
    } else {
      return <Navigate to="/signin" state={{ from: location }} replace />;
    }
  }

  return children;
};

const RedirectIfAuthenticated = ({ children }) => {
  const token = localStorage.getItem('authToken');

  if (token) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <RequireAuth>
        <Layout />
      </RequireAuth>
    ),
    children: [
      {
        path: APP_ROUTE.DASHBOARD.PATH,
        element: <DashboardPage />,
      },
      {
        path: APP_ROUTE.SEARCH_COLLECTION_DETAIL.PATH,
        element: <SearchCollectionDetailPage />,
      },
      {
        path: APP_ROUTE.SEARCH_KEYWORD_HISTORY.PATH,
        element: <SearchKeywordHistoryPage />,
      },
      {
        path: APP_ROUTE.SEARCH_RESULT_DETAIL.PATH,
        element: <SearchResultDetailPage />,
      },
    ],
  },
  {
    path: '/signin',
    element: (
      <RedirectIfAuthenticated>
        <SignInPage />
      </RedirectIfAuthenticated>
    ),
  },
  {
    path: '/signup',
    element: (
      <RedirectIfAuthenticated>
        <SignUpPage />
      </RedirectIfAuthenticated>
    ),
  },
  {
    path: '*',
    element: <Navigate to={APP_ROUTE.DASHBOARD.PATH} replace />, // Redirect to home
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
