import React from 'react';
import Table from '../../components/Table';
import Typography from '../../components/Typography';
import SearchService from '../../services/Search';
import { Box, CircularProgress, IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTE } from '../../router/constant';
import datetimeDisplay from '../../utils/datetimeDisplay';
import { useSnackbar } from '../../providers/SnackBarProvider';

const SEARCH_RESULT_COLUMN = [
  { id: 'id', label: 'Search Result ID' },
  { id: 'search_collection_id', label: 'Search Collection ID' },
  {
    id: 'created_at',
    label: 'Created At',
    align: 'right',
    minWidth: 170,
  },
  {
    id: 'keyword',
    label: 'Keyword',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'total_adwords',
    label: 'Ad Words (Total)',
    align: 'right',
  },
  {
    id: 'total_links',
    label: 'Links (Total)',
    align: 'right',
  },
  {
    id: 'total_search_result_count',
    label: 'Search Result (Total)',
    align: 'right',
  },
  {
    id: 'total_search_result_time',
    label: 'Search Result Time (sec)',
    align: 'right',
  },
];

const ActionButton = ({ row }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const path = APP_ROUTE.SEARCH_RESULT_DETAIL.PATH.replace(':id', row.id);
    navigate(path);
  };

  return (
    <IconButton aria-label="Example" onClick={handleClick}>
      <ChevronRightIcon />
    </IconButton>
  );
};

const actions = [{ label: 'View', button: ActionButton }];

const SearchResultsBox = React.forwardRef(({ searchCollectionId, keyword }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const { showSnackbar } = useSnackbar();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  React.useEffect(() => {
    const fetchSearchResultsData = async () => {
      setLoading(true);
      try {
        const result = await SearchService.getSearchResultsByQuery({
          ...(searchCollectionId && { searchCollectionId: searchCollectionId }),
          ...(keyword && { keyword: keyword }),
          page: page + 1,
          limit: rowsPerPage,
        });

        const formattedRows = result?.searchResults.map((row) => ({
          ...row,
          created_at: datetimeDisplay(row.created_at), // Format search_at with displayDateTime
        }));

        setRows(formattedRows);

        setTotal(result?.total);
      } catch (error) {
        showSnackbar({
          message: 'Loading failed. Please try again later.',
          severity: 'error',
        });
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchSearchResultsData();
  }, [showSnackbar, searchCollectionId, keyword, page, rowsPerPage]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '80vh',
        mt: 2,
        alignItems: 'flex-start',
        flexDirection: 'column',
        pb: 6,
      }}
    >
      <Typography.Title sx={{ marginTop: '16px' }}>
        Search Results
      </Typography.Title>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          marginTop: '10px',
          height: '100%',
          width: '100%',
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table
            columns={SEARCH_RESULT_COLUMN}
            rows={rows}
            total={total}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            actions={actions}
          />
        )}
      </Box>
    </Box>
  );
});

export default SearchResultsBox;
