import React from 'react';
import Table from '../../components/Table';
import Typography from '../../components/Typography';
import SearchService from '../../services/Search';
import { Box, CircularProgress, IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTE } from '../../router/constant';
import Button from '../../components/Button';
import datetimeDisplay from '../../utils/datetimeDisplay';
import { useSnackbar } from '../../providers/SnackBarProvider';

const SEARCH_COLLECTION_COLUMN = [
  { id: 'id', label: 'Search Collection ID' },
  { id: 'total_keyword', label: 'Total Keywords' },
  {
    id: 'search_at',
    label: 'Search At',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170,
    align: 'right',
  },
];

const NavigateButton = ({ row }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const path = APP_ROUTE.SEARCH_COLLECTION_DETAIL.PATH.replace(':id', row.id);
    navigate(path);
  };

  return (
    <IconButton onClick={handleClick}>
      <ChevronRightIcon />
    </IconButton>
  );
};

const actions = [{ label: 'View', button: NavigateButton }];

const SearchCollectionsBox = React.forwardRef((_props, ref) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const { showSnackbar } = useSnackbar();

  React.useImperativeHandle(ref, () => ({
    refreshSearchCollections: () => {
      fetchSearchCollectionsData();
    },
  }));

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchSearchCollectionsData = async () => {
    setLoading(true);
    try {
      const result = await SearchService.getSearchCollectionsByQuery({
        page: page + 1,
        limit: rowsPerPage,
      });

      const formattedRows = result?.searchCollections.map((row) => ({
        ...row,
        search_at: datetimeDisplay(row.search_at), // Format search_at with displayDateTime
      }));

      setRows(formattedRows);

      setTotal(result?.total);
    } catch (error) {
      showSnackbar({
        message: 'Loading failed. Please try again later.',
        severity: 'error',
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  React.useEffect(() => {
    fetchSearchCollectionsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          mt: 2,
          justifyContent: 'space-between',
        }}
      >
        <Typography.Title>Latest Search</Typography.Title>
        <Button onClick={fetchSearchCollectionsData}>Refresh</Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          mt: 2,
          height: '100%',
          width: '100%',
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table
            columns={SEARCH_COLLECTION_COLUMN}
            rows={rows}
            total={total}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            actions={actions}
          />
        )}
      </Box>
    </>
  );
});

export default SearchCollectionsBox;
