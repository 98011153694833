import {
  Table as MTable,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from '@mui/material';
import React from 'react';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Table = ({
  columns = [],
  rows = [],
  total = 0,
  page,
  rowsPerPage,
  handleChangePage = () => {},
  handleChangeRowsPerPage = () => {},
  actions = [],
}) => {
  return (
    <Paper
      sx={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}
    >
      <TableContainer sx={{ flex: 1, overflow: 'auto' }}>
        <MTable stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              {actions.map((action) => {
                return (
                  <TableCell align="center" key={action.label}>
                    {action.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number'
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  {actions.length > 0 && (
                    <>
                      {actions.map((action, index) => {
                        const ActionComponent = action.button;
                        return (
                          <TableCell align="center" key={index}>
                            <ActionComponent key={index} row={row} />
                          </TableCell>
                        );
                      })}
                    </>
                  )}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </MTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default Table;
