import { Button as MButton } from '@mui/material';

const Button = ({
  startIcon,
  endIcon,
  children,
  color = 'primary',
  ...rest
}) => {
  return (
    <MButton
      variant="contained"
      endIcon={endIcon}
      startIcon={startIcon}
      color={color}
      {...rest}
    >
      {children}
    </MButton>
  );
};

export default Button;
