const APP_ROUTE = {
  DASHBOARD: {
    PATH: '/',
    TITLE: 'Dashboard',
  },
  SEARCH_COLLECTION_DETAIL: {
    PATH: '/search-collection/:id',
    TITLE: 'Search Collection Detail',
  },
  SEARCH_KEYWORD_HISTORY: {
    PATH: '/find-keyword-history',
    TITLE: 'Find Keyword History',
  },
  SEARCH_RESULT_DETAIL: {
    PATH: '/search-result/:id',
    TITLE: 'Search Result Detail',
  },
};

export { APP_ROUTE };
