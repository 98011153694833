import React, { useState } from 'react';
import { TextField, Box, Container, Paper } from '@mui/material';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo/GOO_WHITE_REC.png';
import { useAuth } from '../../providers/AuthProvider';
import { useSnackbar } from '../../providers/SnackBarProvider';

const SignInPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { signin } = useAuth();

  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userData = { username, password };

    try {
      await signin(userData);
      showSnackbar({
        message: 'Signed in successfully! Redirect you to the application :)',
        severity: 'success',
      });

      navigate('/');
    } catch (error) {
      showSnackbar({
        message: 'Sign in failed. Please check your username and password.',
        severity: 'error',
      });
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        mt: '8vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Paper elevation={3} sx={{ p: 3 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: { xs: 350, md: 250 },
              p: 3,
            }}
            alt="GoooSearch logo"
            src={logo}
          />

          <Typography.Title>Sign In</Typography.Title>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
              color={'primary.main'}
            >
              <Button href="/signup" variant="body2">
                Don't have an account? Sign up
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default SignInPage;
