import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import SearchService from '../../services/Search';
import datetimeDisplay from '../../utils/datetimeDisplay';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PreviewIcon from '@mui/icons-material/Preview';
import Button from '../../components/Button';
import { useSnackbar } from '../../providers/SnackBarProvider';

const SearchResultDetailPage = () => {
  const { id } = useParams();
  const [searchResult, setSearchResult] = React.useState();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const { showSnackbar } = useSnackbar();

  React.useEffect(() => {
    const fetchSearchCollectionData = async () => {
      setLoading(true);
      try {
        const result = await SearchService.getSearchResultById(id);
        const { searchResult } = result;

        setSearchResult({
          id: searchResult.id,
          searchCollectionId: searchResult.search_collection_id,
          createdAt: searchResult.created_at,
          keyword: searchResult.keyword,
          totalAdwords: searchResult.total_adwords,
          totalLinks: searchResult.total_links,
          totalSearchResultCount: searchResult.total_search_result_count,
          totalSearchResultTime: searchResult.total_search_result_time,
          htmlContent: searchResult.html,
        });
        setLoading(false);
      } catch (error) {
        showSnackbar({
          message: 'Loading failed. Please try again later.',
          severity: 'error',
        });
      }
    };

    fetchSearchCollectionData();
  }, [showSnackbar, id]);

  const handleCopyRawHtml = () => {
    navigator.clipboard.writeText(searchResult.htmlContent);
    showSnackbar({
      message: 'Raw HTML copied to clipboard!',
      severity: 'success',
    });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (loading || !searchResult) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          mb: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          Search Collection Details
        </Typography>
        <Typography variant="body1">
          <strong>ID:</strong> {searchResult.id}
        </Typography>
        <Typography variant="body1">
          <strong>searchCollectionId:</strong> {searchResult.searchCollectionId}
        </Typography>
        <Typography variant="body1">
          <strong>created_at:</strong> {datetimeDisplay(searchResult.createdAt)}
        </Typography>
        <Typography variant="body1">
          <strong>Keyword:</strong> {searchResult.keyword}
        </Typography>
        <Typography variant="body1">
          <strong>totalAdwords:</strong> {searchResult.totalAdwords}
        </Typography>
        <Typography variant="body1">
          <strong>totalLinks:</strong> {searchResult.totalLinks}
        </Typography>
        <Typography variant="body1">
          <strong>totalSearchResultCount:</strong>{' '}
          {searchResult.totalSearchResultCount}
        </Typography>
        <Typography variant="body1">
          <strong>totalSearchResultTime:</strong>{' '}
          {searchResult.totalSearchResultTime}
        </Typography>
      </Paper>

      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PreviewIcon />}
          onClick={handleOpenModal}
        >
          Preview HTML
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<ContentCopyIcon />}
          onClick={handleCopyRawHtml}
        >
          Copy Raw HTML
        </Button>
      </Box>

      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiPaper-root': {
            overflow: 'hidden',
          },
        }}
      >
        <DialogTitle>HTML Preview</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: searchResult.htmlContent }} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SearchResultDetailPage;
