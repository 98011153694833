import { Box } from '@mui/material';
import React from 'react';
import SearchCollectionsBox from './SearchCollectionsBox';
import SearchKeywordBox from './SearchKeywordBox';

const DashboardPage = () => {
  const searchCollectionTableRef = React.useRef();

  const triggerRefreshSearchCollections = () => {
    if (searchCollectionTableRef.current) {
      searchCollectionTableRef.current.refreshSearchCollections();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'flex-start',
        pb: 6,
      }}
    >
      <SearchKeywordBox
        triggerRefreshSearchCollections={triggerRefreshSearchCollections}
      />

      <SearchCollectionsBox ref={searchCollectionTableRef} />
    </Box>
  );
};

export default DashboardPage;
