import { Typography as MTypography } from '@mui/material';

const Typography = ({ children, ...rest }) => {
  return (
    <MTypography variant="body1" {...rest}>
      {children}
    </MTypography>
  );
};

Typography.Title = ({ children, ...rest }) => {
  return (
    <MTypography variant="h5" {...rest}>
      {children}
    </MTypography>
  );
};

Typography.SubTitle = ({ children, ...rest }) => {
  return (
    <MTypography variant="subtitle2" {...rest}>
      {children}
    </MTypography>
  );
};

Typography.Body = Typography;

export default Typography;
