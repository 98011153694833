const datetimeDisplay = (utcDateTimeString) => {
  const date = new Date(utcDateTimeString);
  const localDateTime = date.toLocaleString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  return localDateTime;
};

export default datetimeDisplay;
