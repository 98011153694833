import { Box, Chip, InputBase, Paper } from '@mui/material';
import {
  Search as SearchIcon,
  InsertDriveFile as InsertDriveFileIcon,
} from '@mui/icons-material';
import React from 'react';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import SearchService from '../../services/Search';
import Papa from 'papaparse';
import { useSnackbar } from '../../providers/SnackBarProvider';

const MAX_KEYWORDS = 100;

const SearchKeywordBox = ({ triggerRefreshSearchCollections }) => {
  const [keywords, setKeywords] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [isErrorKeywordsInput, setIsErrorKeywordsInput] = React.useState(false);
  const [triggerSearch, setTriggerSearch] = React.useState(false);
  const { showSnackbar } = useSnackbar();

  React.useEffect(() => {
    setIsErrorKeywordsInput(keywords.length > MAX_KEYWORDS);
  }, [keywords]);

  const performSearch = async (triggerSearch, keywords) => {
    if (triggerSearch && keywords.length > 0) {
      try {
        await SearchService.searchKeywords(keywords);
        showSnackbar({
          message: 'Searching!',
          severity: 'success',
        });
        setKeywords([]);
        triggerRefreshSearchCollections();
      } catch (error) {
        showSnackbar({
          message: 'Request searching failed. Please try again later.',
          severity: 'error',
        });
      } finally {
        setTriggerSearch(false);
      }
    }
  };

  React.useEffect(() => {
    performSearch(triggerSearch, keywords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSearch, keywords]);

  const handleSearchKeywords = async (event) => {
    event.preventDefault();
    if (inputValue !== '') {
      addKeywords(inputValue.split(','));
    }

    setTriggerSearch(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === ',') {
      event.preventDefault();
      addKeywords(inputValue.split(','));
    }
  };

  const addKeywords = (newKeywords) => {
    const nonDupNewKeywords = [...new Set(newKeywords)].filter(
      (item) => !keywords.includes(item)
    );
    if (keywords && newKeywords.length > 0) {
      setKeywords([...keywords, ...nonDupNewKeywords]);
    }
    setInputValue('');
  };

  const handleDelete = (keywordToDelete) => () => {
    setKeywords(keywords.filter((keyword) => keyword !== keywordToDelete));
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          const keywordList = result.data
            .map((row) => row.keyword)
            .filter(Boolean);
          performSearch(true, keywordList);
        },
        error: (error) => {
          console.error('Error reading CSV file:', error);
        },
      });
    }
  };

  return (
    <>
      <Typography.Title>Search Keywords</Typography.Title>
      <Box
        component={Paper}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'row',
          minHeight: '25vh',
          p: 2,
          gap: 1,
          width: '100%',
          mt: 2,
        }}
      >
        <Box
          component="form"
          onSubmit={handleSearchKeywords}
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            height: '100%',
            alignItems: 'flex-start',
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              alignItems: 'flex-start',
            }}
          >
            <Paper
              sx={{
                display: 'flex',
                flex: 1,
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                border: '1px solid #ccc',
                overflowY: 'auto',
                p: 1,
                width: '100%',
                height: '100%',
                ...(isErrorKeywordsInput && { border: '1px solid red' }),
              }}
            >
              {keywords.map((email, index) => (
                <Chip
                  key={index}
                  label={email}
                  onDelete={handleDelete(email)}
                  sx={{ m: '4px' }}
                />
              ))}
              <InputBase
                sx={{
                  flexGrow: 1,
                  minWidth: '120px',
                  m: '4px',
                  flexBasis: 0,
                  overflowY: 'auto', // Make the InputBase scrollable if content overflows
                }}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={`Add keywords separate by comma.`}
              />
            </Paper>

            <Typography.SubTitle
              color={isErrorKeywordsInput && 'error'}
              sx={{ mt: 1 }}
            >
              {`You have entered ${keywords.length} / ${MAX_KEYWORDS} keywords.`}
            </Typography.SubTitle>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 1,
              // width: '100%',
            }}
          >
            <Button
              type="submit"
              endIcon={<SearchIcon />}
              disabled={isErrorKeywordsInput}
            >
              Search
            </Button>
            <Button endIcon={<InsertDriveFileIcon />} component="label">
              Import
              <input
                type="file"
                accept=".csv"
                hidden
                onChange={handleFileUpload}
              />
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SearchKeywordBox;
